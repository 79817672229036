import React from "react";
import Navbar from "../components/common/Navbar";
import Hero from "../components/HomePage/Hero";
import Contributors from "../components/HomePage/Contributors";
import Footer from "../components/common/Footer";
import Invest from "../components/HomePage/Invest";
import Testimonials from "../components/HomePage/Testimonials";
import Features from "../components/HomePage/Features";
import Pricing from "../components/HomePage/Pricing";
import TopRated from "../components/HomePage/TopRated";
import HowItWorks from "../components/HomePage/HowItWorks";
import TimeLine from "../components/HomePage/TimeLine";

function Index() {
  return (
    <>
      <Navbar />
      <Hero />
      <Contributors />
      <Features />
      <Pricing />
      <TimeLine />
      <TopRated />
      <Testimonials />
      <Invest />
      <HowItWorks />
      <Footer />
    </>
  );
}

export default Index;
