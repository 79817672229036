import React, { useState } from "react";

function TimeLine() {
  const [activeTab, setActiveTab] = useState("buyers");

  const items = [
    {
      number: "01",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      isLeft: false,
      imgSrc: "/assets/images/homepage/01.png", // Replace with your actual image path
    },
    {
      number: "02",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      isLeft: true,
      imgSrc: "/assets/images/homepage/02.png", // Replace with your actual image path
    },
    {
      number: "03",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      isLeft: false,
      imgSrc: "/assets/images/homepage/03.png", // Replace with your actual image path
    },
  ];
  return (
    <>
      <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
        <h1 className="text-2xl md:text-5xl font-bold text-center ">
          How <span className="text-primary">AiNDREA</span> WORKS
        </h1>
      </section>

      <div className="flex justify-center mb-8 md:mb-12">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-l-3xl ${
              activeTab === "buyers"
                ? "bg-primary text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setActiveTab("buyers")}
          >
            FOR BUYERS
          </button>
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-r-3xl ${
              activeTab === "creators"
                ? "bg-primary text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setActiveTab("creators")}
          >
            FOR CREATORS
          </button>
        </div>
      </div>

      <div className="hidden md:block my-10">
        {" "}
        {/* Hidden on small screens, visible on md and above */}
        <div className="md:px-28 px-3">
          <div className="md:px-5  relative">
            {/* Vertical line is only shown on larger screens */}
            <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-0.5 border-gradient transform -translate-x-1/2" />
            {items.map((item, index) => (
              <div key={index} className="flex items-center mb-16 last:mb-0">
                <div className={`md:w-1/2 ${item.isLeft ? "pr-8" : "pl-8"}`}>
                  {item.isLeft && (
                    <div>
                      <p className="text-white bg-primary rounded-3xl p-5 shadow-custom-pink">
                        {item.description}
                      </p>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    className={`md:w-36 md:h-36 rounded-full border-[4px] flex items-center justify-center  ${
                      index % 2 === 0
                        ? "border-[#bfdfca] bg-secondary" // For 01, 03
                        : "border-primary bg-[#fbf5fc]" // For 02, 04
                    }`}
                  >
                    <img
                      src={item.imgSrc}
                      alt={`Step ${item.number}`}
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className={`w-1/2 ${item.isLeft ? "pl-8" : "pr-8"}`}>
                  {!item.isLeft && (
                    <div className="ms-10 ">
                      <p className="text-white bg-secondary rounded-3xl p-5 shadow-custom-green">
                        {item.description}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Small screen layout */}
      <div className="block md:hidden">
        <div className="relative">
          {/* No vertical line for small screens */}
          {items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center last:mb-0"
            >
              {/* Image section */}
              <div className="relative ">
                <div
                  className={`w-24 h-24 rounded-full border-[4px] flex items-center justify-center ${
                    index % 2 === 0
                      ? "border-[#bfdfca] bg-secondary" // For 01, 03
                      : "border-primary bg-[#fbf5fc]" // For 02, 04
                  }`}
                >
                  <img
                    src={item.imgSrc}
                    alt={`Step ${item.number}`}
                    width={100}
                    height={100}
                  />
                </div>
              </div>

              {/* Title and description */}
              <div className="w-full px-4">
                <p
                  className={`text-white my-8 rounded-3xl p-5 ${
                    index === 1 ? "bg-primary" : "bg-secondary"
                  }`}
                >
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TimeLine;
