import React, { useState } from "react";
import { Search, ChevronDown, User, Menu, X } from "lucide-react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
            </Link>
          </div>
          <div className="hidden md:flex items-center justify-center flex-1">
            <div className="relative w-full max-w-xl">
              <div className="absolute inset-y-0 left-0 flex items-center border-r">
                <button className="p-2 text-gray-700 hover:text-gray-900">
                  All Items <ChevronDown className="inline-block w-4 h-4" />
                </button>
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-32 pr-10 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-primary"
              />
              <button className="absolute inset-y-0 right-0 flex items-center px-4 text-white bg-primary hover:bg-black rounded-r-3xl">
                <Search className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className="text-gray-700 hover:text-gray-900">
              Pricing
            </Link>
            <Link
              to="/"
              className="bg-primary text-white px-4 py-2 rounded-3xl hover:bg-black"
            >
              Join our community
            </Link>
            <Link
              to="/"
              className="flex items-center text-gray-700 hover:text-gray-900"
            >
              <User className="w-5 h-5 mr-1" /> Log in
            </Link>
          </div>
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              {isOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden fixed inset-0 z-50 transition-opacity duration-1000 ease-in-out bg-white">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8 border-b">
              <div>
                <img src="/assets/images/logo.svg" alt="Logo" width={"70"} />
              </div>
              <button className="btn" onClick={() => setIsOpen(false)}>
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              <div className="p-4">
                <div className="relative w-full mb-4">
                  <div className="absolute inset-y-0 left-0 flex items-center border-r">
                    <button className="p-2 text-gray-700 hover:text-gray-900">
                      All Items <ChevronDown className="inline-block w-4 h-4" />
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Search..."
                    className="w-full pl-32 pr-10 py-2 border rounded-3xl focus:outline-none"
                  />
                  <button className="absolute inset-y-0 right-0 flex items-center px-4 text-white bg-primary hover:bg-black rounded-r-3xl">
                    <Search className="w-5 h-5" />
                  </button>
                </div>
                <div className="my-4 space-y-2">
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Pricing
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Marketplace
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    How it Works
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    For Creators
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    For Contributors
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Community
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Invest in AiNDREA
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Resources
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Support
                  </Link>
                  <Link
                    to="/"
                    className="block py-2 text-gray-700 hover:text-gray-900"
                  >
                    Contact
                  </Link>
                </div>
                <div>
                  <button className="w-full text-white px-4 py-2 bg-primary rounded-3xl hover:bg-black mb-2">
                    Join our community
                  </button>
                  <button className="w-full flex items-center hover:bg-black justify-center text-gray-700 hover:text-gray-900 border border-gray-300 rounded-3xl px-4 py-2">
                    <User className="w-5 h-5 mr-1" /> Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="hidden md:block border-t">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-24">
          <div className="flex justify-between py-3">
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              Marketplace
            </Link>
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              How it Works
            </Link>
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              For Creators
            </Link>
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              For Contributors
            </Link>
            <Link to="/" className="text-gray-700 hover:text-gray-900">
              Community
            </Link>
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              Invest in AiNDREA
            </Link>
            <Link to="/" className="text-gray-700 hover:text-gray-900">
              Resources
            </Link>
            <Link to="/" className="text-gray-700 hover:text-gray-900">
              Support
            </Link>
            <Link
              to="/"
              className="text-gray-700 hover:text-gray-900"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
