import React, { useState } from "react";

function HowItWorks() {
  const [activeTab, setActiveTab] = useState("buyers");

  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <h1 className="text-2xl md:text-5xl font-bold text-center mb-6 md:mb-8">
        How <span className="text-primary">AiNDREA</span> WORKS
      </h1>

      <div className="flex justify-center mb-8 md:mb-12">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-l-3xl ${
              activeTab === "buyers"
                ? "bg-primary text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setActiveTab("buyers")}
          >
            FOR BUYERS
          </button>
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-r-3xl ${
              activeTab === "creators"
                ? "bg-primary text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setActiveTab("creators")}
          >
            FOR CREATORS
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="w-full md:w-1/2 md:order-2">
          <div className="w-full relative flex justify-center">
            <img
              src="/assets/images/homepage/works.png"
              alt="How it works"
              className="rounded-3xl object-cover md:w-[70%] w-[100%]"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 md:order-1">
          <div className="my-auto">
            <h2 className="text-2xl lg:text-4xl font-semibold mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut.
            </h2>
            <p className="text-gray-600 mb-4">
              Briefly describe the investment opportunity. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
