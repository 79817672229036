import React from "react";

function Hero() {
  return (
    <div className="max-w-8xl px-5 md:px-24 my-16">
      <div className="text-center">
        <h1 className="md:text-5xl text-2xl font-bold leading-normal md:leading-loose">
          Unleash Your <span className="text-[#CA63CE]">Imagination</span>
        </h1>
        <p className="text-md md:text-xl leading-loose">
          Find stunning{" "}
          <span className="text-[#CA63CE]">visuals to enhance</span> your
          creative work.
        </p>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-10">
        {/* Large Single Image */}
        <div className="col-span-6 md:col-span-2 p-2 relative">
          <div className="absolute md:bottom-10 md:left-10 bottom-5 left-5 text-white">
            <h1 className="text-xl">Category 1</h1>
            <p className="text-sm font-muted mt-2">Worefall</p>
          </div>
          <img
            className="w-full h-full object-cover rounded-3xl"
            src="assets/images/homepage/img1.png"
            alt="img1"
          />
        </div>

        {/* Flex Column with Two Images */}
        <div
          className="col-span-6 md:col-span-3 p-2 flex flex-col gap-5 md:gap-2"
          style={{ height: "100%" }}
        >
          <div className="flex-1 relative">
            <img
              className="w-full h-full object-cover rounded-3xl"
              src="assets/images/homepage/img2.png"
              alt="img2"
            />
            <div className="absolute md:bottom-10 md:left-10 bottom-5 left-3 text-white">
              <h1 className="text-xl">Category 1</h1>
              <p className="text-sm font-muted mt-2">Worefall</p>
            </div>
          </div>
          <div className="flex-1 relative">
            <img
              className="w-full h-full object-cover rounded-3xl"
              src="assets/images/homepage/img3.png"
              alt="img3"
            />
            <div className="absolute md:bottom-10 md:left-10 bottom-5 left-3 text-white">
              <h1 className="text-xl">Category 1</h1>
              <p className="text-sm font-muted mt-2">Worefall</p>
            </div>
          </div>
        </div>

        {/* Large Single Image */}
        <div className="col-span-12 md:col-span-3 p-2 relative">
          <div className="absolute md:bottom-10 md:left-10 bottom-5 left-3 text-white">
            <h1 className="text-xl">Category 1</h1>
            <p className="text-sm font-muted mt-2">Worefall</p>
          </div>
          <img
            className="rounded-3xl w-full h-full object-cover"
            src="assets/images/homepage/img4.png"
            alt="img4"
          />
        </div>
        <div
          className="col-span-12 md:col-span-4 p-2 flex flex-col gap-5 md:gap-2"
          style={{ height: "100%" }}
        >
          <div className="flex-1 relative">
            <img
              className="w-full h-full object-cover rounded-3xl"
              src="assets/images/homepage/img5.png"
              alt="img2"
            />
            <div className="absolute md:bottom-10 md:left-10 bottom-5 left-3 text-white">
              <h1 className="text-xl">Category 1</h1>
              <p className="text-sm font-muted mt-2">Worefall</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="grid grid-cols-12 gap-5" style={{ height: "100%" }}>
              <div className="col-span-5 relative">
                <div className="absolute bottom-5 md:bottom-10 left-5 text-white">
                  <h1 className="text-xl">Category 1</h1>
                  <p className="text-sm font-muted mt-2">Worefall</p>
                </div>
                <img
                  className="w-full h-full object-cover rounded-3xl"
                  src="assets/images/homepage/img6.png"
                  alt="img2"
                />
              </div>
              <div className="col-span-7 relative">
                <div className="absolute bottom-5 md:bottom-10 left-5 text-white">
                  <h1 className="text-xl">Category 1</h1>
                  <p className="text-sm font-muted mt-2">Worefall</p>
                </div>
                <img
                  className="w-full h-full object-cover rounded-3xl"
                  src="assets/images/homepage/img7.png"
                  alt="img2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
