import { ChevronRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

function Invest() {
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col lg:flex-row items-center lg:items-stretch gap-8">
        <div className="w-full lg:w-1/2 relative">
          <div className="aspect-w-16 aspect-h-9">
            <img
              src="/assets/images/homepage/invest.png"
              alt="Invest"
              className="rounded-lg object-cover"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center">
          <h2 className="md:text-4xl font-bold text-gray-900 text-2xl mb-4">
            Invest in AiNDREA
          </h2>
          <h3 className="md:text-5xl text-3xl font-normal text-gray-900 mb-4">
            Join Us in Revolutionizing IP Management
          </h3>
          <p className="md:text-lg text-md text-gray-600 mb-6">
            Briefly describe the investment opportunity. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            ut.
          </p>
          <div>
            <Link
              href="/"
              className="inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-3xl shadow-sm text-white bg-black hover:bg-primary"
            >
              Explore Investment Opportunities
              <ChevronRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Invest;
