import React from "react";
import Slider from "react-slick";
import { ArrowIcon } from "../../utils/Icons";

function Contributors() {
  const slider1 = React.useRef(null);
  const slider2 = React.useRef(null);

  const handleNext = () => {
    slider1.current.slickNext();
    slider2.current.slickNext();
  };

  const handlePrev = () => {
    slider1.current.slickPrev();
    slider2.current.slickPrev();
  };

  const NextArrow = () => {
    return (
      <button
        className="custom-arrow next-arrow"
        style={{
          position: "absolute",
          left: "-50px",
          bottom: "-180px",
          zIndex: 2,
        }}
        onClick={handleNext}
      >
        <ArrowIcon color="#CA63CE" size={34} />
      </button>
    );
  };

  const PrevArrow = () => {
    return (
      <button
        className="custom-arrow rotate-180 prev-arrow"
        style={{
          position: "absolute",
          left: "-100px",
          bottom: "-180px",
          zIndex: 2,
        }}
        onClick={handlePrev}
      >
        <ArrowIcon color="gray" size={34} />
      </button>
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false, // Hide arrows on mobile
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false, // Hide arrows on mobile
        },
      },
    ],
  };

  const slidesData = [
    {
      imgSrc: "assets/images/homepage/cont1.png",
      name: "Jon Kantner",
      role: "Designer",
    },
    {
      imgSrc: "assets/images/homepage/cont2.png",
      name: "Jane Doe",
      role: "Developer",
    },
    {
      imgSrc: "assets/images/homepage/cont3.png",
      name: "Mike Smith",
      role: "Manager",
    },
    {
      imgSrc: "assets/images/homepage/cont4.png",
      name: "Sara Wilson",
      role: "Analyst",
    },
    {
      imgSrc: "assets/images/homepage/cont5.png",
      name: "Sara Wilson",
      role: "Analyst",
    },
    {
      imgSrc: "assets/images/homepage/cont6.png",
      name: "Sara Wilson",
      role: "Analyst",
    },
    {
      imgSrc: "assets/images/homepage/cont7.png",
      name: "Sara Wilson",
      role: "Analyst",
    },
    {
      imgSrc: "assets/images/homepage/cont8.png",
      name: "Sara Wilson",
      role: "Analyst",
    },
    // Add more slides as necessary
  ];

  const reversedSlidesData = [...slidesData].reverse();

  return (
    <div className="p-5 md:ps-24 grid grid-cols-12 gap-4 my-10 lg:overflow-hidden">
      <div className="col-span-12 md:col-span-4 flex flex-col justify-center items-start relative">
        <h1 className="md:text-5xl text-2xl font-bold">Our Contributors</h1>
        <p className="md:text-lg text-md my-2 w-[75%] md:my-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <button className="button z-10 black-button bg-black text-lg min-w-[50%] mt-20">
          All Contributors
          <ArrowIcon size={24} />
        </button>
        <img
          className="absolute bottom-0 right-0 md:right-5 md:bottom-20 w-[50%]"
          src="assets/images/homepage/aroow-investor.png"
          alt="arrow"
        />
      </div>

      {/* Hide this section on mobile using Tailwind */}
      <div className="hidden md:block col-span-12 md:col-span-8 relative md:translate-x-[80px] ">
        <img
          className="absolute w-full -z-20 top-[20%]"
          src="assets/images/homepage/bg-cont.png"
          alt=""
        />

        {/* First Slider */}
        <Slider {...settings1} className="first-row md:ms-28" ref={slider1}>
          {slidesData.map((slide, index) => (
            <div key={index} className="p-2">
              <div className="bg-white rounded-3xl p-3 border my-2">
                <img
                  className="rounded-3xl"
                  src={slide.imgSrc}
                  alt={slide.name}
                />
                <div className="flex justify-between items-center py-3">
                  <h1 className="text-md font-bold">{slide.name}</h1>
                  <p className="text-sm">{slide.role}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        {/* Second Slider */}
        <Slider {...settings2} className="second-row md:ms-28" ref={slider2}>
          {reversedSlidesData.map((slide, index) => (
            <div key={index} className="p-2">
              <div className="bg-white rounded-3xl p-3 border my-2">
                <img
                  className="rounded-3xl"
                  src={slide.imgSrc}
                  alt={slide.name}
                />
                <div className="flex justify-between items-center py-3">
                  <h1 className="text-md font-bold">{slide.name}</h1>
                  <p className="text-sm">{slide.role}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Mobile slider */}
      <div className="block md:hidden col-span-12">
        <Slider {...settings1} className="first-row">
          {slidesData.map((slide, index) => (
            <div key={index} className="p-2">
              <div className="bg-white rounded-3xl p-3 border my-2">
                <img
                  className="rounded-3xl w-full"
                  src={slide.imgSrc}
                  alt={slide.name}
                />
                <div className="flex justify-between items-center py-3">
                  <h1 className="text-md font-bold">{slide.name}</h1>
                  <p className="text-sm">{slide.role}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Contributors;
