import React from "react";
import {
  BuyingIcon,
  CommunityFocusIcon,
  RevenueIcon,
  SecureIcon,
  TransactionsIcon,
  VarietyIcon,
} from "../../utils/Icons";

function Features() {
  return (
    <div className="p-5 md:px-24 grid grid-cols-12 gap-4 my-10">
      <div className="col-span-12 md:col-span-4 flex items-center justify-center md:justify-start md:pe-5 md:my-2 my-1">
        <h1 className="text-2xl md:text-5xl font-bold">
          Key Features and Benefits
        </h1>
      </div>
      <div className="col-span-12 md:col-span-4 md:my-2 my-1">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <BuyingIcon />
          </div>
          <p className="font-bold">
            Modern buying option like
            <br /> rental, buying and
            <br /> subscription.
          </p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 md:my-2 my-1">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <TransactionsIcon />
          </div>
          <p className="font-bold">
            Secure and Encrypted
            <br /> Transactions for the ease
            <br /> of mind.
          </p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-3 md:my-2 my-1 md:min-h-[220px]">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <VarietyIcon />
          </div>
          <p className="font-bold">Wide variety of content.</p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-3 md:my-2 my-1">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <RevenueIcon />
          </div>
          <p className="font-bold">90% revenue payout.</p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-3 md:my-2 my-1">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <CommunityFocusIcon />
          </div>
          <p className="font-bold">Community focus..</p>
        </div>
      </div>
      <div className="col-span-12 md:col-span-3 md:my-2 my-1">
        <div className="w-full h-full border shadow-inner flex flex-col justify-center items-center px-5 py-8 text-center gap-5 rounded-3xl">
          <div className="bg-primary  rounded-full flex justify-center items-center w-20 h-20">
            <SecureIcon />
          </div>
          <p className="font-bold">Secure content protection..</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
