import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const testimonials = [
  {
    id: 1,
    quote:
      "Real Plastic Surgeons. Real Results. The Delicate Blending Science & Nature. Enhance Your Look Your Life. Look How You Feel. Love How You Look. Shaping Your Dreams Into Reality. A Meticulous Approach To Natural Results.",
    name: "Rashedul Islam",
    title: "Ceo of Lorem ipsum",
    avatar: "/assets/images/homepage/testimonials/1.png",
  },
  {
    id: 2,
    quote:
      "Exceptional service and outstanding results. The team's expertise and attention to detail exceeded my expectations. I couldn't be happier with my experience.",
    name: "Sarah Johnson",
    title: "Marketing Director",
    avatar: "/assets/images/homepage/testimonials/1.png",
  },
  {
    id: 3,
    quote:
      "From consultation to post-operative care, every step was handled with utmost professionalism. The results are natural and exactly what I hoped for.",
    name: "Michael Chen",
    title: "Software Engineer",
    avatar: "/assets/images/homepage/testimonials/1.png",
  },
];

function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const nextTestimonial = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      setFade(false);
    }, 300); // Duration matches CSS transition
  };

  const prevTestimonial = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + testimonials.length) % testimonials.length
      );
      setFade(false);
    }, 300); // Duration matches CSS transition
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <div className="flex flex-col lg:flex-row items-center lg:items-center gap-8">
        <div className="w-full lg:w-1/2 relative">
          <div className="aspect-w-16 aspect-h-9">
            <img
              src="/assets/images/homepage/testi.png"
              alt="Invest"
              className="rounded-lg object-cover md:w-[85%]"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center">
          <div>
            <div className="bg-primary text-white text-lg font-semibold px-5 py-2 rounded-full inline-block mb-4">
              Testimonial
            </div>
          </div>
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Client's <span className="text-primary">Feedback</span>
          </h2>
          <div
            className={`relative transition-opacity duration-300 ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            <img
              src="/assets/images/homepage/testimonials/colons.png"
              alt="Colons"
              className="absolute -top-24 right-0 w-[10%] -mt-4 -ml-2"
            />
            <p className="text-lg text-gray-600 mb-6 relative z-10">
              "{currentTestimonial.quote}"
            </p>
          </div>
          <div
            className={`flex items-center justify-between transition-opacity duration-300 ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="flex items-center">
              <img
                src={currentTestimonial.avatar}
                alt={currentTestimonial.name}
                width={50}
                height={50}
                className="rounded-full mr-4"
              />
              <div>
                <div className="font-semibold text-gray-900">
                  {currentTestimonial.name}
                </div>
                <div className="text-sm text-gray-500">
                  {currentTestimonial.title}
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={prevTestimonial}
                className="p-2 rounded-full bg-purple-100 text-primary hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-primary"
                aria-label="Previous testimonial"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              <button
                onClick={nextTestimonial}
                className="p-2 rounded-full bg-primary text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-primary"
                aria-label="Next testimonial"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
