import { ChevronRight } from "lucide-react";
import React from "react";

function Pricing() {
  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <h1 className="text-2xl md:text-5xl font-bold text-center mb-2">
        Get Started
      </h1>
      <p className="md:text-lg text-md text-center mb-12">
        Flexible <span className="text-primary">Pricing Plans</span> for Every
        Need
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-0 items-center">
        {/* Rent Assets Card */}
        <div className="bg-white rounded-3xl shadow-custom h-auto md:h-96">
          <div className="p-6">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">
              Rent Assets
            </h2>
            <p className="mb-4">
              Get access to a vast library of photos, videos, and AI images for
              a limited time.
            </p>
            <p className="mb-6">
              Perfect for short-term projects or one-time use. Lorem ipsum dolor
              sit amet consectetur adipisicing elit. Neque sequi dolorem,
              voluptate voluptatum quae eos corrupti consequatur dolore eligendi
              est?
            </p>
          </div>
          <div className="px-6 pb-6">
            <button className="w-full inline-flex justify-center bg-black text-white rounded-3xl py-2 px-4 hover:bg-primary transition duration-300">
              Start Now <ChevronRight className="ms-3" />
            </button>
          </div>
        </div>

        {/* Buy Card */}
        <div className="bg-primary text-white rounded-3xl  shadow-custom h-auto md:h-72">
          <div className="p-6">
            <h2 className="text-2xl md:text-3xl text-center font-bold mb-4">
              Buy
            </h2>
            <p className="mb-4">
              Purchase the rights to use our high-quality visuals forever.
            </p>
            <p className="mb-6">
              Ideal for long-term projects or permanent needs.
            </p>
          </div>
          <div className="px-6 pb-6">
            <button className="w-full inline-flex justify-center bg-white text-primary rounded-3xl py-2 px-4 hover:bg-black hover:text-white transition duration-300">
              Start Now <ChevronRight className="ms-3" />
            </button>
          </div>
        </div>

        {/* Subscribe Card */}
        <div className="bg-white rounded-3xl shadow-custom h-auto md:h-96">
          <div className="p-6">
            <h2 className="text-2xl md:text-3xl text-center font-bold mb-4">
              Subscribe
            </h2>
            <p className="mb-4">
              Unlock unlimited access to our entire collection with a monthly or
              annual subscription.
            </p>
            <p className="mb-6">
              Perfect for professional creators. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quisquam reprehenderit eligendi
              molestiae illum sed, deleniti neque. Natus iusto nihil, laboriosam
              voluptatem sed quia accusamus?
            </p>
          </div>
          <div className="px-6 pb-6">
            <button className="w-full inline-flex justify-center bg-black text-white rounded-3xl py-2 px-4 hover:bg-primary transition duration-300">
              Start Now <ChevronRight className="ms-3" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
