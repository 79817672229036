import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";

const professionals = [
  { id: 1, image: "/assets/images/homepage/toprated/rated.png" },
  { id: 2, image: "/assets/images/homepage/toprated/rated.png" },
  { id: 3, image: "/assets/images/homepage/toprated/rated.png" },
  { id: 4, image: "/assets/images/homepage/toprated/rated.png" },
  { id: 5, image: "/assets/images/homepage/toprated/rated.png" },
];

function TopRated() {
  const [currentIndex, setCurrentIndex] = useState(2);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % professionals.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + professionals.length) % professionals.length
    );
  };

  return (
    <section className="max-w-8xl mx-auto py-12 px-4 sm:px-6 lg:px-24">
      <h2 className="text-2xl md:text-5xl font-bold text-center mb-16">Top Rated</h2>
      <div className="relative h-[500px]">
        <div className="flex justify-center items-center h-full">
          {professionals.map((professional, index) => {
            let offset = index - currentIndex;
            if (offset < -2) offset += professionals.length;
            if (offset > 2) offset -= professionals.length;

            const isVisible = isMobile ? Math.abs(offset) <= 0 : Math.abs(offset) <= 2;

            return (
              <div
                key={professional.id}
                className={`absolute transition-all duration-300 ease-in-out ${
                  isVisible ? "opacity-100" : "opacity-0"
                }`}
                style={{
                  transform: `translateX(${offset * (isMobile ? 0 : 265)}px) scale(${
                    1 - Math.abs(offset) * (isMobile ? 0.5 : 0.01)
                  }) perspective(500px) rotateY(${offset * (isMobile ? 0 : -10)}deg)`,
                  zIndex: 2 - Math.abs(offset),
                }}
              >
                <div className="w-72 h-102 rounded-3xl overflow-hidden border-4 border-primary shadow-lg">
                  <img
                    src={professional.image}
                    alt={`Professional ${professional.id}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <button
          onClick={prevSlide}
          className="absolute z-10 left-0 top-1/2 transform -translate-y-1/2 bg-primary text-white rounded-full p-3 focus:outline-none hover:bg-black transition-colors"
          aria-label="Previous slide"
        >
          <ChevronLeftIcon className="w-8 h-8" />
        </button>
        <button
          onClick={nextSlide}
          className="absolute z-10 right-0 top-1/2 transform -translate-y-1/2 bg-primary text-white rounded-full p-3 focus:outline-none hover:bg-black transition-colors"
          aria-label="Next slide"
        >
          <ChevronRightIcon className="w-8 h-8" />
        </button>
      </div>
    </section>
  );
}

export default TopRated;
