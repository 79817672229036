import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollToTop from "./components/common/ScrollToTop";
import Index from "./pages/Index";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Index />} />
        {/* <Route exact path="*" element={<Error />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
